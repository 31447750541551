var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',{staticClass:"mb-2 text-gray-800 underlined"},[_vm._v("Add Project")]),_c('div',{staticClass:"add-project"},[_c('div',{staticClass:"menu",attrs:{"id":"add-project-menu"}},[_c('nav',{staticClass:"nav flex-column"},[_c('router-link',{staticClass:"nav-link active",attrs:{"to":{ name: 'dashboard.organizations.projects.create' }}},[_c('i',{staticClass:"fa fa-file me-2"}),_vm._v("Registration Form")]),_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'dashboard.organizations.projects.create.summary' }}},[_c('i',{staticClass:"fad fa-list me-2"}),_vm._v("Project Summary")]),_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'dashboard.organizations.projects.create.details' }}},[_c('i',{staticClass:"fad fa-file-alt me-2"}),_vm._v("Project Details")]),_c('router-link',{staticClass:"nav-link",attrs:{"to":{
            name: 'dashboard.organizations.projects.create.additionality',
          }}},[_c('i',{staticClass:"far fa-file-plus me-2"}),_vm._v("Additionality")]),_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'dashboard.organizations.projects.create.benefits' }}},[_c('i',{staticClass:"fad fa-thumbs-up me-2"}),_vm._v("Other Benefits")]),_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'dashboard.organizations.projects.create.transition' }}},[_c('i',{staticClass:"far fa-redo me-2"}),_vm._v("Transition")]),_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'dashboard.organizations.projects.create.validation' }}},[_c('i',{staticClass:"fal fa-check-square me-2"}),_vm._v("Validation / Verification")]),_c('router-link',{staticClass:"nav-link",attrs:{"to":{
            name: 'dashboard.organizations.projects.create.mitigations',
          }}},[_c('i',{staticClass:"fad fa-cogs me-2"}),_vm._v("Project Mitigations")]),_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'dashboard.organizations.projects.create.location' }}},[_c('i',{staticClass:"fad fa-map-marker me-2"}),_vm._v("Project Location")]),_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'dashboard.organizations.projects.create.users' }}},[_c('i',{staticClass:"fad fa-users me-2"}),_vm._v("Authorized Reps")]),_c('router-link',{staticClass:"nav-link",attrs:{"to":{
            name: 'dashboard.organizations.projects.create.documentation',
          }}},[_c('i',{staticClass:"fal fa-file-pdf me-2"}),_vm._v("Documentation")]),_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'dashboard.organizations.projects.create.finish' }}},[_c('i',{staticClass:"fad fa-check me-2"}),_vm._v("Finish")])],1)]),_c('div',{staticClass:"content"},[_c('router-view')],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }