<template>
  <div>
    <h3 class="mb-2 text-gray-800 underlined">Add Project</h3>
    <div class="add-project">
      <div class="menu" id="add-project-menu">
        <nav class="nav flex-column">
          <router-link
            class="nav-link active"
            :to="{ name: 'dashboard.organizations.projects.create' }"
            ><i class="fa fa-file me-2"></i>Registration Form</router-link
          >
          <router-link
            class="nav-link"
            :to="{ name: 'dashboard.organizations.projects.create.summary' }"
            ><i class="fad fa-list me-2"></i>Project Summary</router-link
          >
          <router-link
            class="nav-link"
            :to="{ name: 'dashboard.organizations.projects.create.details' }"
            ><i class="fad fa-file-alt me-2"></i>Project Details</router-link
          >
          <router-link
            class="nav-link"
            :to="{
              name: 'dashboard.organizations.projects.create.additionality',
            }"
            ><i class="far fa-file-plus me-2"></i>Additionality</router-link
          >
          <router-link
            class="nav-link"
            :to="{ name: 'dashboard.organizations.projects.create.benefits' }"
            ><i class="fad fa-thumbs-up me-2"></i>Other Benefits</router-link
          >
          <router-link
            class="nav-link"
            :to="{ name: 'dashboard.organizations.projects.create.transition' }"
            ><i class="far fa-redo me-2"></i>Transition</router-link
          >
          <router-link
            class="nav-link"
            :to="{ name: 'dashboard.organizations.projects.create.validation' }"
            ><i class="fal fa-check-square me-2"></i>Validation /
            Verification</router-link
          >
          <router-link
            class="nav-link"
            :to="{
              name: 'dashboard.organizations.projects.create.mitigations',
            }"
            ><i class="fad fa-cogs me-2"></i>Project Mitigations</router-link
          >
          <router-link
            class="nav-link"
            :to="{ name: 'dashboard.organizations.projects.create.location' }"
            ><i class="fad fa-map-marker me-2"></i>Project Location</router-link
          >
          <router-link
            class="nav-link"
            :to="{ name: 'dashboard.organizations.projects.create.users' }"
            ><i class="fad fa-users me-2"></i>Authorized Reps</router-link
          >
          <router-link
            class="nav-link"
            :to="{
              name: 'dashboard.organizations.projects.create.documentation',
            }"
            ><i class="fal fa-file-pdf me-2"></i>Documentation</router-link
          >
          <router-link
            class="nav-link"
            :to="{ name: 'dashboard.organizations.projects.create.finish' }"
            ><i class="fad fa-check me-2"></i>Finish</router-link
          >
        </nav>
      </div>
      <div class="content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
